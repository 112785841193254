.main {
    border: 1px solid #E1E1E1;
    /* border-bottom: none; */
    display: flex;
    padding-left: 10px;

}
.label{
    float: left;
    padding-top: 10px;
    font-weight: bold;
}
.field{
    float: left;
}
.JobListFooter{
    background: #EEEEEE;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.JobListFooter span a{
    font-size: clamp(10px, 1.5vw, 16px);
    font-family: var(--secondary-font);
    color: #3F464E;
    font-weight: bold;
    
}
.JobListHeader{
    border: 1px solid #E1E1E1;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 20px;
}
.JobListHeader span{
    font-family: var(--primary-font);
    font-size: clamp(18px, 1.5vw, 24px);
    color: #3F464E;
    padding: 30px;
    margin: 0;
}
.job-detail{
    display: flex;
    flex-direction: row;
    font-family: var(--secondary-font);
    font-style: normal;
    color: #666666;
    white-space: nowrap;
    font-size: small;
    flex-wrap: wrap;
    
}
/* .img{
    height: 100px;
    width: 100px;
    margin: 15px;
    margin-right: 0px;
    padding-left: 10px;
    padding-top: 5px;


} */
.mapJobList{
    border-bottom: 1px solid #E1E1E1;
}
.similar-job{
    margin-top: 20px;
}
.title{
    padding-left: 20px;
    margin-top: 20px;
    font-family: var(--primary-font);
    font-size: clamp(14px, 1.5vw, 20px);
}
.sub-title{
    font-family: var(--primary-font);
    font-size: clamp(14px, 1.5vw, 20px);
}

.jobsCardImg {
    object-fit: none;
  }
.body{
    flex: 1;
    display: flex;
}
.right-end{
    flex: 1;
    /* display: flex; */
    flex-direction: column;
    font-family: var(--secondary-font);
    font-style: normal;
}
.buttons{
    display: flex;
    align-items: right;
    justify-content: right;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 30px;
    color: #3F464E;
}
.span{
    display: flex;
    align-items: right;
    justify-content: right;
    margin-right: 35px;
    margin-bottom: 10px;
    color: #666666;
    white-space: nowrap;
    font-size: small;
}
.button1 {
    background-color: #F4F1F0;
    display: inline-block;
    border: none;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    font-size: small;
    margin: 4px 2px;
    border-radius: 30px;
    
}
.button2 {
    background-color: #F4F1F0;
    display: inline-block;
    border: none;
    font-size: small;
    padding: 10px 12px;
    text-align: center;
    text-decoration: none;
    margin: 4px 2px;
    border-radius: 100px;
}
@media screen and (max-width: 900px) {
    .Filter{
        display: none;
    }
}
@media screen and (max-width: 500px) {
    .main { 
     display: flex;
     flex-direction: column;
     padding: 0px;
   }
   /* .img img{
    height: 70px;
    width: 70px;
    } */

    .job-detail{
        size: 5px;
        font-size: small;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .title{
        display: contents;
        padding-left: 0px;
        display: flex;
        flex-direction: column;
        width: 70%;
    }
    .span{
        margin-right: 20px;
    }
    .buttons{
        margin-right: 20px;
    }
    .button1{
        padding: 4px 14px;
        font-size: small;
    }
    .button2{
        padding: 8px 15px;
        font-size: small;
    }
    .location{
        margin-bottom: 7px;
    }
    .right-end{
        /* display: flex; */
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }

    .JobListHeader {
        text-align: center;
    }

    .Filter{
        display: none;
    }

 }