.btn-event-sv{
    width: 130px;
    height: 42px;
    background-color: #06c864;
    color: white;
    border-radius: 6px;
    border: none;
}
.img-sc-event{
    background:rgb(0, 0, 0, 0.6) url('/src/Assets/images/event-closeup.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 353px;
    border-radius: 12px;
    background-blend-mode: darken;
}
.main-div-img-sc-ev{
    position: relative;
    top: 190px;
}
.margin{
    margin-left: 20px;
}
.txt-event{
    color: white;
    line-height: 2.5;
    font-size: 14px;
    font-family: 'Roboto';
}
.sav-ev-txt{
    font-size: 15px;
    margin-top: 4px;
    margin-left: 5px;
    font-family: "Roboto" !important    ;
}
.bookmark-span{
    width: 38px;
    height: 38px;
    border-radius: 38px;
    background-color: #06c864;
    border: none;
}
@media screen and (max-width: 992px){
    .create-btn-event{
        margin-bottom: 10px;
    }
    .para-btn{
        flex-direction: column;
    }
    .img-sc-event{
        height: 425px;
    }
    .detail-btn-ev{
        margin-left: 20px;
    }
    .main-div-img-sc-ev{
        position: relative;
        top: 140px;
    }
}

@media screen and (max-width:567px){
    .img-sc-event{
        height: 425px;
        margin-top: 20px;
    }
    .sav-ev-txt{
        margin-top: 3px;
    }
}

@media screen and (max-width:388px){
    .img-sc-event{
        height: 425px;
    }
    .main-div-img-sc-ev{
        position: relative;
        top: 95px;
    }
}