.ImagePreview {
  max-height: 300px;
  img {
    height: 300px;
    object-fit: cover;
  }
}

.postImageDiv{
  width: 50px;
  
}
.CreatePostImage{
  width: 100%;
  height: 50px;
}
.cursorPointer{
  cursor: pointer;
}