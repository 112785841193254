.event-card{
    height: 40px;
    width: 40px;
    border: 1px solid transparent;
    background-color: white;
    border-radius: 40px;
}
.main-event-trend{
    display: grid;
    grid-template-columns: auto auto auto auto auto;

}
.event-tcard-title{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    font-weight: 400;
    margin-top: 10px;
    font-family: 'Roboto';
    font-size: 16px;
}

@media screen and (max-width:767px){
    .main-event-trend{
        display: grid;
        grid-template-columns: auto auto auto;
    
    }
}