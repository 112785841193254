.cl-06C864{
    color: #06C864;
}
.bg-06C864{
    background-color: #06C864;
}

.bg-card-06C864{
    background-color: #06C864;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom: 1px solid var(--db-color);
    color: #fff;
}

.commentInput{
    border: 2px solid #06C864 !important;
    border-radius: 50px;
    padding: 10px 50px 10px 10px;
}

.comment-emoji{
    position: relative;
    left: -35px;
}

.card.bg-white{
    background-color: transparent !important;
}