.more_community_card_footer{
    background-color: var(--lsh-color) !important;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.custom-light-green{
    color: rgb(55, 209, 170);
}
.custom-bg-light{
    background-color: var(--lsh-color)
}

.t-img img{
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.custom-gradient-button {
    background-image: linear-gradient(135deg,#04d78c,#3f464e);
    border-radius: 10px;
    outline: none;
    border: none;
    width: 125px;
    font-size: clamp(14px,1.5vw,16px);
    font-family: "Roboto Medium",sans-serif;
    padding: 10px;
    color: #f4f1f0;
    margin-left: 10px;
}

.custom-gradient-button-invite {
    background-image: linear-gradient(135deg,#04d78c,#3f464e);
    border-radius: 10px;
    outline: none;
    border: none;
    width: 155px;
    font-size: clamp(14px,1.5vw,16px);
    font-family: "Roboto Medium",sans-serif;
    padding: 10px;
    color: #f4f1f0;
    margin-left: 10px;
}

.custom-gradient-button-leave {
    background-image: linear-gradient(135deg,#04d78c,#3f464e);
    border-radius: 10px;
    outline: none;
    border: none;
    width: 125px;
    font-size: clamp(14px,1.5vw,16px);
    font-family: "Roboto Medium",sans-serif;
    padding: 10px;
    color: #f4f1f0;
    margin-left: 0px;
}

.custom-active-input:focus{
    background-color: transparent !important;
    -webkit-box-shadow: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important;
}

.custom-text-dots{
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}