.PostStatus {
  background-color: #f4f1f0;
  border-radius: 30px;
}
.feedMain {
  height: 90vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.feedMain::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 1400px) {
  .feedMain {
    height: 100vh;
  }
}
@media only screen and (max-width: 1600px) {
  .feedMain {
    height: 110vh;
  }
}
// @media only screen and (max-width: 2000px) {
//   .feedMain {
//     height: 120vh;
//   }
// }
// @media only screen and (max-width: 2500px) {
//   .feedMain {
//     height: 130vh;
//   }
// }