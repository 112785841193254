.jobs-banner{
background: transparent linear-gradient(111deg, #037430 0%, #04401E 100%) 0% 0% no-repeat padding-box;
height: 80vh;
background-size: cover;
background-repeat: no-repeat;
display: flex;
align-items: center;
clip-path: ellipse(84% 60% at 48% 39%);
}
.search-bar form{
display: grid;
 grid-template-columns: 1fr 1fr 55px;
 background: #fff;
 align-items: center;
 padding: 5px;
 border-radius: 5px;
 box-shadow: 0px 3px 6px #72727226;;
}
.gen-btn{
background: #247E48;
border: 1px solid #247E48;
}
/* .search-bar form input{
 box-shadow: none !important;
 border: 0px;
} */
.search-bar form #keyword_id{
border: 0px;
border-radius: 0px;
 border-right: 1px solid #eee !important;
}
.search-bar form #location_id{
border: 0px;
border-radius: 0px;
 border-left: 1px solid #eee !important;
}
/* .search-bar form input:nth-child(2){
 border-left: 1px solid #eee;
} */


.popular-searches-carousel h2 span:first-child{
 z-index: 1;
 position: relative;
}


@media (max-width: 767px) {
    .jobs-banner{
     height: 70vh;
    }
   
}
@media (min-width:768px) and (max-width:1024px) {
    .jobs-banner{
     height: 60vh;
    }
}
