/* input{
    background: #FFFFFF;
    border: 2px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 6px;
} */

.row-1{
    margin-bottom: 20px;
}
.label{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #3F464E;
    margin-bottom: 10px;
}
.flex-column{
    display: flex;
    flex-direction: column;
}
.form-headline{
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    /* text-transform: uppercase; */
    color: #3F464E;
    margin-bottom: 20px;
}
.form-footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
}
.small-line-msg span{
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    color: #888888;
}
.form-btn{
    width: 210px;
    height: 60px;
    background: linear-gradient(182.24deg, #04D76A 0%, #3F464E 118.68%);
    border-radius: 6px;
    border: none;
}
.form-btn span{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
}
.form-textarea{
    border-radius: 10px;
    border: 2px solid #CCCCCC;
    width: 100%;
    height: 200px;
    padding: 10px;
    font-size: 14px;
}
.fields-contact{
    border-radius: 5px;
    border: 2px solid #CCCCCC;
    height: 40px;
    padding: 10px;
    font-size: 14px;
}
.error{
    font-size: small;
    color: red;
    margin-top: 5px;
}

.contact-panel-main{
   
    text-align: center;
}
.social{
    display: flex;
    justify-content: space-around;
}
.contact-panel-main{
    display: flex;
    flex-direction: column;
    /* padding-left: 20px; */
}
.contact-panel-heading span{
    width: 184px;
    height: 25px;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-transform: uppercase;
    color: #3F464E;

}
.connect{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #3F464E;
}
.contact-panel-detail{
    margin-bottom: 20px;
}
.contact-panel-heading{
    margin-bottom: 10px;
}
.contact-panel-detail span{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #3F464E;
}
.contact-panel-detail h4{
    margin-top: 10px;
    font-size: 16px;
}


.modal-header{
    border: none;
}
.modal-content{
    border-radius: 20px;
}
/* .btn-close ::before{
    color: "black";
}
.btn-close{
    background: #F4F1F0;
    border-radius: 51px;
    color: red;
} */


@media screen and (max-width: 500px) {
    .form-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .form-btn{
        margin-top: 20px;
    }
    .row-1{
        flex-direction: column;
    }

}

@media (min-width: 576px){
    .modal-dialog {
        max-width: 750px !important;
        margin: 1.75rem auto;
    }
    .social{
        display: flex;
        flex-direction: row;
    }
    .social-space{
        padding-right: 20px;
        padding-top: 20px;
    }
}

