.register {
  background: linear-gradient(160deg, #1bb661, #18341f);
  min-height: 100vh;
}
.register .grid--col-02 {
  display: grid;
  grid-template-columns: 700px auto;
}
.bg-form-style {
  background: #f6f2e4;
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}
.logo-width {
  width: 100px;
}
.register .react-multi-carousel-dot button {
  width: 14px !important;
  border: 0px !important;
  height: 2px !important;

  border-radius: 0px !important;
}
.icon-group img {
  width: 35px;
  height: 35px;
}
.register .react-multi-carousel-dot {
  bottom: -4px !important;
}
.register .react-multi-carousel-dot button {
  color: #eee;
  opacity: 0.4;
}
.register .react-multi-carousel-dot--active button {
  background-color: #fff !important;

  opacity: 1 !important;
}

.register input {
  background: #ffffff26 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #4b4b4b0d;
  border: 1px solid #d9d9d9;
  border-radius: 2px !important;
}

.register textarea:focus{
  background: none !important;
  box-shadow: none !important;
  border: 1px solid #d9d9d9;
  border-radius: 2px !important;
}

.register input:focus{
  background: none !important;
  box-shadow: none !important;
}

.registerMultiInput input {
  border: none;
}

.register label {
}
.register .input-group > .form-control:focus,
.register .form-control:focus {
  background: transparent !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 2px;
  z-index: 0 !important;
}

.graphic-style {
  width: 350px !important;
  height: 235px;
  object-fit: contain;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .bg-form-style {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    position: relative;
    padding: 0px !important;
  }
  .register .grid--col-02 {
    grid-template-columns: 1fr;
  }
  /* .bg-form-style:before{
content: '';
background-image: url('src/Assets/images/register/logo.png');
position: absolute;
z-index: 2;
width: 141px;
height: 141px;
 top: 30px;
 left: 70px;
}
.bg-form-style:after{
content: '';
background: linear-gradient(176deg, #20CD68 0%, #3C5250 100%);
position: absolute;
border-bottom-right-radius: 100% ;
z-index: 1;
width: 100%;
height: 200px;
top: 0px;
padding: 0px !important;
} */
}
@media (max-width: 767px) {
  .bg-form-style {
    border-radius: 0px !important;
  }
  .register .grid--col-02 {
    grid-template-columns: 1fr;
  }
}
