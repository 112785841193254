.cat-sub-title{
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}

.cat-card-img{
    height: 50px;
    width: 50px;
    background-color: #dcdddc;
    border-radius: 50px;
    margin: 0 auto;

}
.cat-txt{
    font-size: 16px;
    text-align: center;
    margin-top: 60px;
}

.cat-card{
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 20px;
    margin-bottom: 10px;
    height: 180px;
    width: 180px;
}

.cat-heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin-bottom: 20px; */
}

.cat-btn{
    border: none;
    width: 164px;
    height: 46px;
    background: linear-gradient(182.24deg, #04D76A 0%, #3F464E 118.68%);
    border-radius: 6px;
}

.cat-btn span{
    width: 124px;
    height: 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    color: #FFFFFF;

}

.cat-icon{
    position: relative;
    top: 15%;
    left: 25%;
}


@media screen and (max-width: 500px){
    .cat-btn{
        width: 140px;
        height: 40px;
    }
    .t-space{
        padding: 0px;
    }
    .has-search .form-control{
        padding-left: 10px;
    }
    .cat-heading {
        padding-top: 10px;
    }
    .cat-button{
        text-align: end;
        margin-bottom: 20px;
        /* margin-top: 10px; */
    }
    .marBot {
        margin: 0px;
    }

}