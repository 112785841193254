body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Poppins Semi Bold";
  src: url("./Assets/fonts/Poppins-SemiBold.eot");
  src: url("./Assets/fonts/Poppins-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("./Assets/fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("./Assets/fonts/Poppins-SemiBold.woff") format("woff"),
    url("./Assets/fonts/Poppins-SemiBold.ttf") format("truetype"),
    url("./Assets/fonts/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("./Assets/fonts/Roboto-Regular.eot");
  src: url("./Assets/fonts/Roboto-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./Assets/fonts/Roboto-Regular.woff2") format("woff2"),
    url("./Assets/fonts/Roboto-Regular.woff") format("woff"),
    url("./Assets/fonts/Roboto-Regular.ttf") format("truetype"),
    url("./Assets/fonts/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Medium";
  src: url("./Assets/fonts/Roboto-Medium.eot");
  src: url("./Assets/fonts/Roboto-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./Assets/fonts/Roboto-Medium.woff2") format("woff2"),
    url("./Assets/fonts/Roboto-Medium.woff") format("woff"),
    url("./Assets/fonts/Roboto-Medium.ttf") format("truetype"),
    url("./Assets/fonts/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circular Std";
  src: url("./Assets/fonts/CircularStd-Bold.eot");
  src: url("./Assets/fonts/CircularStd-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./Assets/fonts/CircularStd-Bold.woff2") format("woff2"),
    url("./Assets/fonts/CircularStd-Bold.woff") format("woff"),
    url("./Assets/fonts/CircularStd-Bold.ttf") format("truetype"),
    url("./Assets/fonts/CircularStd-Bold.svg#CircularStd-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Light";
  src: url("./Assets/fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"),
    url("./Assets/fonts/Roboto-Light.woff2") format("woff2"),
    url("./Assets/fonts/Roboto-Light.woff") format("woff"),
    url("./Assets/fonts/Roboto-Light.ttf") format("truetype"),
    url("./Assets/fonts/Roboto-Light.svg#Roboto-Light") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto Italic";
  src: url("./Assets/fonts/Roboto-LightItalic.ttf");
  font-weight: normal;
  font-style: italic;
}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.5; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  opacity: 0.7;
}

::-ms-input-placeholder { /* Microsoft Edge */
  opacity: 0.7;
}

/* Recoleta Font */

@font-face {
    font-family: 'Recoleta';
    src: url('./Assets/fonts/recoleta/Recoleta-Black.eot');
    src: url('./Assets/fonts/recoleta/Recoleta-Black.eot?#iefix') format('embedded-opentype'),
        url('./Assets/fonts/recoleta/Recoleta-Black.woff2') format('woff2'),
        url('./Assets/fonts/recoleta/Recoleta-Black.woff') format('woff'),
        url('./Assets/fonts/recoleta/Recoleta-Black.ttf') format('truetype'),
        url('./Assets/fonts/recoleta/Recoleta-Black.svg#Recoleta-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recoleta';
    src: url('./Assets/fonts/recoleta/Recoleta-Regular.eot');
    src: url('./Assets/fonts/recoleta/Recoleta-Regular.eot?#iefix') format('embedded-opentype'),
        url('./Assets/fonts/recoleta/Recoleta-Regular.woff2') format('woff2'),
        url('./Assets/fonts/recoleta/Recoleta-Regular.woff') format('woff'),
        url('./Assets/fonts/recoleta/Recoleta-Regular.ttf') format('truetype'),
        url('./Assets/fonts/recoleta/Recoleta-Regular.svg#Recoleta-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recoleta';
    src: url('./Assets/fonts/recoleta/Recoleta-SemiBold.eot');
    src: url('./Assets/fonts/recoleta/Recoleta-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./Assets/fonts/recoleta/Recoleta-SemiBold.woff2') format('woff2'),
        url('./Assets/fonts/recoleta/Recoleta-SemiBold.woff') format('woff'),
        url('./Assets/fonts/recoleta/Recoleta-SemiBold.ttf') format('truetype'),
        url('./Assets/fonts/recoleta/Recoleta-SemiBold.svg#Recoleta-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recoleta';
    src: url('./Assets/fonts/recoleta/Recoleta-Light.eot');
    src: url('./Assets/fonts/recoleta/Recoleta-Light.eot?#iefix') format('embedded-opentype'),
        url('./Assets/fonts/recoleta/Recoleta-Light.woff2') format('woff2'),
        url('./Assets/fonts/recoleta/Recoleta-Light.woff') format('woff'),
        url('./Assets/fonts/recoleta/Recoleta-Light.ttf') format('truetype'),
        url('./Assets/fonts/recoleta/Recoleta-Light.svg#Recoleta-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recoleta';
    src: url('./Assets/fonts/recoleta/Recoleta-Thin.eot');
    src: url('./Assets/fonts/recoleta/Recoleta-Thin.eot?#iefix') format('embedded-opentype'),
        url('./Assets/fonts/recoleta/Recoleta-Thin.woff2') format('woff2'),
        url('./Assets/fonts/recoleta/Recoleta-Thin.woff') format('woff'),
        url('./Assets/fonts/recoleta/Recoleta-Thin.ttf') format('truetype'),
        url('./Assets/fonts/recoleta/Recoleta-Thin.svg#Recoleta-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recoleta';
    src: url('./Assets/fonts/recoleta/Recoleta-Medium.eot');
    src: url('./Assets/fonts/recoleta/Recoleta-Medium.eot?#iefix') format('embedded-opentype'),
        url('./Assets/fonts/recoleta/Recoleta-Medium.woff2') format('woff2'),
        url('./Assets/fonts/recoleta/Recoleta-Medium.woff') format('woff'),
        url('./Assets/fonts/recoleta/Recoleta-Medium.ttf') format('truetype'),
        url('./Assets/fonts/recoleta/Recoleta-Medium.svg#Recoleta-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recoleta Alt';
    src: url('./Assets/fonts/recoleta/RecoletaAlt-Black.eot');
    src: url('./Assets/fonts/recoleta/RecoletaAlt-Black.eot?#iefix') format('embedded-opentype'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Black.woff2') format('woff2'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Black.woff') format('woff'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Black.ttf') format('truetype'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Black.svg#RecoletaAlt-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recoleta Alt';
    src: url('./Assets/fonts/recoleta/RecoletaAlt-Thin.eot');
    src: url('./Assets/fonts/recoleta/RecoletaAlt-Thin.eot?#iefix') format('embedded-opentype'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Thin.woff2') format('woff2'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Thin.woff') format('woff'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Thin.ttf') format('truetype'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Thin.svg#RecoletaAlt-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recoleta Alt';
    src: url('./Assets/fonts/recoleta/RecoletaAlt-SemiBold.eot');
    src: url('./Assets/fonts/recoleta/RecoletaAlt-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./Assets/fonts/recoleta/RecoletaAlt-SemiBold.woff2') format('woff2'),
        url('./Assets/fonts/recoleta/RecoletaAlt-SemiBold.woff') format('woff'),
        url('./Assets/fonts/recoleta/RecoletaAlt-SemiBold.ttf') format('truetype'),
        url('./Assets/fonts/recoleta/RecoletaAlt-SemiBold.svg#RecoletaAlt-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recoleta Alt';
    src: url('./Assets/fonts/recoleta/RecoletaAlt-Regular.eot');
    src: url('./Assets/fonts/recoleta/RecoletaAlt-Regular.eot?#iefix') format('embedded-opentype'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Regular.woff2') format('woff2'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Regular.woff') format('woff'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Regular.ttf') format('truetype'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Regular.svg#RecoletaAlt-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recoleta Alt';
    src: url('./Assets/fonts/recoleta/RecoletaAlt-Bold.eot');
    src: url('./Assets/fonts/recoleta/RecoletaAlt-Bold.eot?#iefix') format('embedded-opentype'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Bold.woff2') format('woff2'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Bold.woff') format('woff'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Bold.ttf') format('truetype'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Bold.svg#RecoletaAlt-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recoleta Alt';
    src: url('./Assets/fonts/recoleta/RecoletaAlt-Light.eot');
    src: url('./Assets/fonts/recoleta/RecoletaAlt-Light.eot?#iefix') format('embedded-opentype'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Light.woff2') format('woff2'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Light.woff') format('woff'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Light.ttf') format('truetype'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Light.svg#RecoletaAlt-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recoleta';
    src: url('./Assets/fonts/recoleta/Recoleta-Bold.eot');
    src: url('./Assets/fonts/recoleta/Recoleta-Bold.eot?#iefix') format('embedded-opentype'),
        url('./Assets/fonts/recoleta/Recoleta-Bold.woff2') format('woff2'),
        url('./Assets/fonts/recoleta/Recoleta-Bold.woff') format('woff'),
        url('./Assets/fonts/recoleta/Recoleta-Bold.ttf') format('truetype'),
        url('./Assets/fonts/recoleta/Recoleta-Bold.svg#Recoleta-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recoleta Alt';
    src: url('./Assets/fonts/recoleta/RecoletaAlt-Medium.eot');
    src: url('./Assets/fonts/recoleta/RecoletaAlt-Medium.eot?#iefix') format('embedded-opentype'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Medium.woff2') format('woff2'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Medium.woff') format('woff'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Medium.ttf') format('truetype'),
        url('./Assets/fonts/recoleta/RecoletaAlt-Medium.svg#RecoletaAlt-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recoleta';
    src: url('./Assets/fonts/recoleta/Recoleta-Black_1.eot');
    src: url('./Assets/fonts/recoleta/Recoleta-Black_1.eot?#iefix') format('embedded-opentype'),
        url('./Assets/fonts/recoleta/Recoleta-Black_1.woff2') format('woff2'),
        url('./Assets/fonts/recoleta/Recoleta-Black_1.woff') format('woff'),
        url('./Assets/fonts/recoleta/Recoleta-Black_1.ttf') format('truetype'),
        url('./Assets/fonts/recoleta/Recoleta-Black_1.svg#Recoleta-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

