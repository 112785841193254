.ans-main{
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px;
}

.previous-title-bar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 35px;
}

.view-span{
    font-family: 'Circular Std';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
display: flex;
align-items: center;

color: #999999;

}
.previous-span{
    font-family: 'Circular Std';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
display: flex;
align-items: center;

color: #3F464E;
}

.textarea-ans-form{
        border-radius: 10px;
        border: 0px solid #CCCCCC;
        width: 100%;
        height: 120px;
        padding: 10px;
        font-size: 14px;
}
.form-ans-btn{
    width: 150px;
    height: 39px;
    background: linear-gradient(182.24deg, #04D76A 0%, #3F464E 118.68%);
    border-radius: 6px;
    border: none;
    color: white;
}
.reply-answers{
    display: flex;
    flex-direction: column;
    
}
.detail-icons{
    padding: 1px;
}
.ans-main{
    flex-direction: column;
}
.answer-detail p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: justify;
    margin-top: 10px;
    color: #666666;
}

.pre-ans-title{
    padding-left: 12px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    font-family: var(--primary-font);
    font-size: 20px !important;
    justify-content: center;
}
.job-type a{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    color: #06C864;
}
.previous-answers-main{
    padding-top: 20px;
    padding-bottom: 20px;
}
.trending-main{
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 10px;

}
.trending-main h5
{
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    color: #3F464E;
}
.trending-card h3{
    font-family: 'Circular Std';
font-style: normal;
font-weight: 400;
font-size: 17px;
line-height: 23px;
display: flex;
align-items: center;

color: #3F464E;
}

.ql-toolbar.ql-snow{
    background-color: #f6f6f6;
    border: 1px solid;
    border-color: #f6f6f6;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 8px;
}
.ql-container.ql-snow{
    border: 1px solid #ccc;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.trending-card{
    padding: 10px;
    border-top: 1px solid #E1E1E1;
}

.span1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #888888;
    padding-right: 5px;
}

.span2{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #06C864;
}

@media screen and (max-width:500px){
    .pre-ans-title{
        padding-left: 10px;
        margin-top: 18px;
        display: flex;
        flex-direction: column;
        font-family: var(--primary-font);
        font-size: 10px !important;
        justify-content: center;
    }
    .answer-detail p{
        word-break: break-all;
    }
    .ans-form-txt{
        font-size: 22px !important;
    }
    .quill{
        margin-bottom: 70px !important;
        height: 120px !important;
    }
    .ans-title-resp{
        font-size: 14px;
    }
    .ans-p-resp{
        font-size: 12px !important;
    }
}

@media screen and (max-width:767px){
    .trending-main{
        margin-top: 20px;
    }
}