.time-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
/* The container */
.round_checkmark_container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.round_checkmark_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.round_checkmark {
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: white;
  border: 2px solid var(--primary-color);
  border-radius: 50px;
  color: #3f464e;
  font-family: var(--secondary-font-medium);
  line-height: 1 !important;
}
.round_checkmark p {
  line-height: 1 !important;
  font-family: var(--secondary-font-medium) !important;
}
/* On mouse-over, add a grey background color */
.round_checkmark_container:hover input ~ .round_checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.round_checkmark_container input:checked ~ .round_checkmark {
  background-color: var(--primary-color);
  color: white;
}

/* Create the round_checkmark/indicator (hidden when not checked) */
.round_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the round_checkmark when checked */
.round_checkmark_container input:checked ~ .round_checkmark:after {
  display: block;
}

/* Style the round_checkmark/indicator */
.round_checkmark_container .round_checkmark:after {
  /* left: 10px;
  top: 10px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); */
}
