.img-upl{
    background-color: rgb(220, 221, 220);
    height: 100px;
    width: 100px;
    border-radius: 50px;
}
.img-icon{
    background: url(src/Assets/images/icons/gallery.png);
    background-repeat: no-repeat;
    height: 100px;
    width: 100px;
    margin: 17px !important;
}

.img-upload-cre-coomunity{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px;
    height: 160px;
}
.img-upl-btn{
    background: transparent;
    border: 0px;
    margin-top: 5px;
    color: #8d8d8d;
}

.input-community{
    opacity: 0 !important;
}