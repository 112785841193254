.grid-2{
    grid-template-columns: repeat(2, 1fr);
}
.u-desc p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #666666;
}
.u-subTitle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.u-st-1{
    padding-right: 20px;
}
.u-title {
    padding-top: 10px;
}
.u-title span{
    font-size: 24px;
}
.u-btn{
    background: #06C864;
    border-radius: 6px;
    border: none;
    width: 148px;
    height: 46px;
}
.u-btn span{
    width: 89px;
    height: 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
}
.pad{
    padding: 30px;
}

@media screen and (max-width: 750px) {
    .grid-2{
        grid-template-columns: 1fr; 
    }
}