.t-bottom-padding{
    padding-bottom: 10px;
    justify-content: start;
    padding-left: 10px;
    padding-top: 0px;
    
}

.has-search .form-control {
    padding-left: 2.375rem;
    background-color: #F4F1F0;
    border-radius: 6px;
    font-size: 16px;
    margin-bottom: 10px;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #666666;
    font-size: 16px;
}
.icon-search{
    position: absolute;
    padding: 10px;
    color: #06C864;
}