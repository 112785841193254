.JobContainer {
  background: #ffffff;
  box-shadow: 0px 10px 25px rgba(4, 215, 106, 0.26);
  border-radius: 20px;
  padding: 15px;

  p {
    margin: 0 !important;
  }
  .ct-c {
    color: #999999;
  }
}
