.lng-border {
  border: 1px solid #cccccc;
}
.footer {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  background: #f5f5f5;
}
.powered {
  background: var(--h-color);
  padding-top: 5px;
  padding-bottom: 5px;
  color: white !important;
  /* margin-bottom: 20px; */
}
.powered a {
  color: white !important;
}
.footer-line-height {
  line-height: 25px;
}
.footer-pad {
  padding: 10px;
}
.footer {
  /* position: absolute; */
  width: 100%;
  bottom: 0;
}
