.sidebar-main{
    /* border-bottom: 1px solid #E1E1E1; */
    box-sizing: border-box;
    /* border-radius: 12px 0px 0px 12px; */
    
}
.side-of-chat{
    border: 1px solid #E1E1E1;
    border-radius: 12px 0px 0px 12px;
}
.recent-chats{
    overflow-y: scroll;
    height: 300px;
    width: 300px;
    margin: 0px;
    /* border: 1px solid #E1E1E1; */
}
.contacts-chats{
    overflow-y: scroll;
    height: 400px;
    width: 300px;
    margin: 0px;
    border-bottom: none;
}
.sidebar-card{
    display: flex;
    flex-direction: row;
    border-top: 1px solid #E1E1E1;
    padding: 10px;

}
.sidebar-body{
    padding-left: 10px;
}
.sidebar-title span{
    width: 64px;
    height: 24px;  
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #3F464E;
}
.sidebar-sub-title span{
    width: 216px;
    height: 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #666666;
}
.sidebar-time span{
    width: 77px;
    height: 14px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #999999;
    }
.head-2 span{
    width: 72px;
    height: 14px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #999999;
    letter-spacing: 2px;
}
.contacts-heading{
    border: 1px solid #E1E1E1;
    border-bottom: none;
    padding: 20px;
    height: 60px;
}
.head-1 span{
    width: 49px;
    height: 23px;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #3F464E;
}
.sidebar-header{
    padding: 10px;
}

.contact-head-border{
    border-top: 1px solid #E1E1E1;
}