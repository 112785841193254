.mb-80 {
  margin-bottom: -100px;
}
.FindVendor h2 span {
  z-index: 1;
  position: relative;
}

.FindVendor svg {
  width: 12px;
  height: 12px;
  vertical-align: initial;
  color: #149448;
}

.horizontal-scroll-container {
  display: flex;
  overflow-x: auto;
  flex-direction: row;
  flex-wrap: nowrap;
  scroll-behavior: smooth;
}

.horizontal-scroll-container::-webkit-scrollbar {
  display: none;
}
.horizontal-scroll-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.horizontal-scroll-item:nth-child(odd) {
  z-index: 2;
}
.horizontal-scroll-text {
  font-weight: 500;
  z-index: 1;
}
