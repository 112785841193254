:root {
  --white: #fff;
  /* --orange: #155930; */
}

body {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
}

.outer-container {
  max-width: 800px;
  margin: auto;
  display: flex;
  overflow: hidden;
}

.outer-container > div {
  flex: 1;
  padding: 20px;
}

.outer-container .carousel-wrapper {
  /* background: var(--orange); */
  display: flex;
  flex-direction: column;
}

.outer-container .carousel-wrapper p,
.carousel-item {
  display: initial !important;
  /* font-weight: 700; */
  font-size: 20px;
  color: var(--white);
  line-height: 1.3;
}

.outer-container .content {
  background: var(--white);
}

.leading-text {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 6px;
}

.carousel {
  height: 100%;
  display: flex;
  flex: 5;
  align-items: center;
  color: var(--white);
}

.carousel-button {
  display: flex;
  align-self: center;
  padding: 10px;
  /* max-width: 50px; */
  border-radius: 30px;
  background: var(--orange);
  border: 2px solid var(--white);
}

.carousel-button svg {
  height: 26px;
  width: 26px;
}
.carousel-button path {
  fill: var(--white);
}

.slides {
  align-self: flex-start;
  flex: 1;
  width: 100%;
  /* overflow: hidden; */
}

.carousel-inner {
  position: relative;
  max-height: 300px;
  height: 300px;
  overflow: initial !important;
}

.carousel-item {
  position: absolute;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  opacity: 0;
  top: 120px;
  transition: transform 0.4s ease, opacity 0.4s ease;
  letter-spacing: 0px;
  font-family: Recoleta;
}

.activedDiv {
  opacity: 1;
  font-size: 50px;
  font-weight: 500;
}
.nearestDeactivedDiv {
  opacity: 0.3;
  font-size: 40px;
  font-weight: 300;
}
.deactivedDiv {
  opacity: 0.6;
  font-size: 50px;
  font-weight: 300;
}

@media only screen and (max-width: 1150px) {
  .activedDiv {
    font-size: 40px;
  }
  .nearestDeactivedDiv {
    font-size: 30px;
  }
  .deactivedDiv {
    font-size: 40px;
  }
  .buildQuery {
    font-size: 30px;
  }
}
@media only screen and (max-width: 1000px) {
  .activedDiv {
    font-size: 35px;
  }
  .nearestDeactivedDiv {
    font-size: 25px;
  }
  .deactivedDiv {
    font-size: 35px;
  }
  .buildQuery {
    font-size: 30px;
  }
}
@media only screen and (max-width: 870px) {
  .activedDiv {
    font-size: 30px;
  }
  .nearestDeactivedDiv {
    font-size: 20px;
  }
  .deactivedDiv {
    font-size: 30px;
  }
  .buildQuery {
    font-size: 25px;
  }
}
@media only screen and (max-width: 770px) {
  .typewritrerDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 550px) {
  .typewritrerDiv h1 {
    font-size: 30px !important;
  }
}

@media only screen and (max-width: 700px) {
  .staticDiv1 {
    display: none;
  }
  .staticDiv2 {
    display: none;
  }
  .blinkingDiv {
    width: 30px;
    height: 10px;
  }
}
