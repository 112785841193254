.Q-main{
    padding-top: 30px;
}

.icon-div{
    text-align: end;
    padding-right: 40px;
}
.input-font{
    font-size: 10px;
}
.q-title{
    padding-left: 0px;
}
.q-img{
    margin-top: 10px;
}

.q-main{
    border: 1px solid #E1E1E1;
    /* border-radius: 10px; */
    margin-bottom: 10px;
    margin-top: 10px;
    /* padding-bottom: 20px; */
}

.q-button{
    width: 40px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 50%;
    border: none;
    margin: 10px;
}
.q-heading h2{
    font-family: 'Circular Std';
    font-style: normal;
    font-size: 24px;
    color: #3F464E;
    padding-bottom: 10px;
    padding-top: 10px;

}
@media screen and (max-width: 500px){
    .q-img img{
        height: 50px;
        width: 50px;
    }
    .q-img{
        padding: 0px;
        width: 50px;
    }
    .q-right-side{
        justify-content: center;
    }
}