.img-upl{
    background-color: rgb(220, 221, 220);
    height: 100px;
    width: 100px;
    border-radius: 50px;
}

.img-upload-cre-coomunity{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px;
    height: 160px;
}
.img-upl-btn{
    background: transparent;
    border: 0px;
    margin-top: 5px;
    color: #8d8d8d;
}
.create-event-main{
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px;
}
.img-upload-cre-event{
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px;
    height: 160px;
}
.submit-event-btn{
    width: 130px;
    height: 48px;
    background: linear-gradient(182.24deg, #04D76A 0%, #3F464E 118.68%);
    border-radius: 6px;
    border: none;
    color: white;
}