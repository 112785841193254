.notification_container {
  padding: 0.5rem 1rem;
  background-color: var(--primary-lite-color);
  border-radius: 5px;
  font-size: clamp(12px, 1vw, 14px);
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.notification_text {
  color: rgb(74 74 74);
}
.notification_time {
  font-size: clamp(11px, 0.8vw, 12px);
}
.notification_image {
  margin-right: 10px;
}
