/* .nav-bar-bg {
  box-shadow: 0px 1px 10px #888888;
} */

.bg-color {
  background: #197641;
}
@media only screen and (max-width: 990px) {
  .headerBgColor {
    background: #197641;
  }
  .collapseNavBar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
