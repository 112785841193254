.commentBg {
  background: #f2f2f2;
}
.SharedContainer {
  border: 1px solid var(--db-color);
}

#post-action {
  background-color: transparent !important;
  color: var(--dt-color);
  border: 0px;
  &:hover,
  &:active {
    border: 0px;
  }
}
.postImage{
  object-fit: cover;
    width: 100%;
    height: auto;
}