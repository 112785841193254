.create-btn-event{
    width: 130px;
    height: 48px;
    background: linear-gradient(182.24deg, #04D76A 0%, #3F464E 118.68%);
    border-radius: 6px;
    border: none;
}
.create-btn-event:hover{
    background: linear-gradient(182.24deg, #04D76A 0%, #3F464E 118.68%);
}
.detail-btn-ev:hover{
    background: linear-gradient(182.24deg, #04D76A 0%, #3F464E 118.68%);
}
.txt-event{
    color: white;
    line-height: 2.5;
    font-size: 14px;
    font-family: 'Roboto';
}
.event-image{
    max-width: 100%;
    margin-bottom: 15px;
}
.sp-title{
    position: relative;
    display: inline-block;
}
.ev-sub-title{
    position: absolute;
    bottom: 0%;
    left: 16px;
    line-height: 2.5;
}
.ev-btn{
    position: absolute;
  bottom: 20px;
  right: 16px;
}
.detail-btn-ev{
    width: 130px;
    height: 42px;
    background-color: #06c864;
    border-radius: 6px;
    border: none;
}
.trending-txt{
    width: 150px;
    height: 30px;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #3F464E;
    white-space: nowrap;
}

.img-sect-event{
    background:rgb(0, 0, 0, 0.6) url('/src/Assets/images/event-banner.png');
    background-size: cover;
    height: 353px;
    border-radius: 12px;
    background-blend-mode: darken;
}
.main-div-img-sect-ev{
    position: relative;
    top: 192px;
}
.margin{
    margin-left: 20px;
}
@media screen and (max-width: 767px){
    .create-btn-event{
        margin-bottom: 10px;
    }
    .para-btn{
        flex-direction: column;
    }
    .img-sect-event{
        height: 353px;
    }
    .detail-btn-ev{
        margin-left: 20px;
    }
    .main-div-img-sect-ev{
        position: relative;
        top: 140px;
    }
}
@media screen and (max-width: 992px){
    .create-btn-event{
        margin-bottom: 10px;
    }
    .para-btn{
        flex-direction: column;
    }
    .img-sect-event{
        height: 425px;
    }
    .detail-btn-ev{
        margin-left: 20px;
    }
    .main-div-img-sect-ev{
        position: relative;
        top: 140px;
    }
}

@media screen and (max-width:567px){
    .img-sect-event{
        height: 425px;
    }
}

@media screen and (max-width:388px){
    .img-sect-event{
        height: 405px;
    }
    .main-div-img-sect-ev{
        position: relative;
        top: 20px;
    }
}