.back-btn{
    background-color: white;
    border: none;
}
.back-btn:hover{
    background-color: white;
}
.btn-bookmark{
    background-color: #06c864;
    border: none;
    width: 130px;
    height: 42px;
    border-radius: 6px;
    color: white;
}