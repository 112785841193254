.cards{
    background: white;
}
.modal-body{
    padding: 0px !important;
}

.reg-ev-col{
    padding: 0px !important;
    background-color: #f8f7fa;
    border-radius: 20px;
}
.reg-free-btn{
    float: right;
    background: linear-gradient(182.24deg, #06C864 0%, #3F464E 118.68%);
    margin-top: 200px;
    font-size: 14px;
    color: white;
    border: none;
    width: 100px;
    height: 36px;
    border-radius: 6px;
    margin-bottom: 10px;
}
.select-ev{
    width: 90px;
    height: 45px;
    border-radius: 0px;
    margin-top: 15px;
}
.modal-80w {
    min-width:60% !important;
  }

.main-card-reg{
    border: 1px solid transparent;
    background-color: white;
    border-radius: 20px;
  }

  .check-reg{
    border: 1px solid #06C864;
    width: 18px;
    height: 18px;
    border-radius: 2px;

  }

  .reg-free-btnn{
    float: right;
    background: linear-gradient(182.24deg, #06C864 0%, #3F464E 118.68%);
    margin-top: 50px;
    font-size: 14px;
    color: white;
    border: none;
    width: 100px;
    height: 36px;
    border-radius: 6px;
    margin-bottom: 10px;
}

.pay-card{
    border: 1px solid #ced4da;
    border-radius: 5px;

}
.paypal-icon{
    background-color: #1072b8;
    border: 1px solid #1072b8;
    padding: 15px;
    border-radius: 5px;
    width: 60px;
    height: 40px;
    padding: 15px !important;
    margin-right: 15px;
}