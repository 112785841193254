.chat-main {
  border: 1px solid #e1e1e1;
  border-left: none;
  box-sizing: border-box;
  border-radius: 0px 12px 12px 0px;
}
.main-of-chat-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.chat-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #3f464e;
  border-top-right-radius: 12px;
}
.chat-header-1 {
  display: flex;
  flex-direction: row;
  padding: 21px;
}
.chat-user-detail {
  margin-left: 10px;
}
.chat-title span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.chat-status {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}

.btn-1 {
  border: none;
  width: 36px;
  height: 36px;
  background: #04d76a;
  border-radius: 34px;
  margin-left: 10px;
}
.btn-0 {
  border: none;
  width: 36px;
  height: 36px;
  background: #3f464e;
  border-radius: 34px;
  margin-left: 10px;
}
.chat-header-buttons {
  display: flex;
  flex-direction: row;
  padding: 20px;
}
.main-box-of-chat {
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 0px 12px 12px 0px;
  border-left: none;
}

.send-btn {
  width: 50px;
  height: 50px;
  background: linear-gradient(180deg, #06c864 0%, #029348 100%);
  border-radius: 30px;
  border: none;
}
.chat-footer {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.chat-text-field {
  width: 850px;
  height: 50px;
  background: #ffffff;
  border: 2px solid rgba(4, 215, 106, 0.5);
  box-sizing: border-box;
  border-radius: 25px;
  padding-left: 20px;
  margin-right: 20px;
}
.main-box-of-chat {
  display: flex;
  flex-direction: column;
  width: 990px;
  /* justify-content: space-between; */
}
.chat-body {
  overflow-y: scroll;
  height: 650px;
  margin: 0px;
}
.incoming-message {
  background: #9befc3cc;
  border-radius: 0px 16px 16px 16px;
  width: fit-content;
  height: fit-content;
  padding: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-left: 20px;
}
.incoming-message span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3f464e;
  text-align: center;
}
.outgoing-main {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.outgoing-message {
  background: #eeeeee;
  border-radius: 16px 0px 16px 16px;
  width: fit-content;
  height: fit-content;
  padding: 10px;
  margin-top: 20px;
  margin-right: 20px;

}
.outgoing-message span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3f464e;
  text-align: center;
}

@media only screen and (max-width: 1410px) {
  .main-box-of-chat {
    width: 800px;
  }
  .chat-text-field {
    width: 700px;
  }
}

@media only screen and (max-width: 1200px) {
  .main-box-of-chat {
    width: 650px;
  }
  .chat-text-field {
    width: 500px;
  }
}

@media only screen and (max-width: 990px) {
  .main-box-of-chat {
    width: 500px;
  }
  .chat-text-field {
    width: 350px;
  }
}

@media only screen and (max-width: 770px) {
  .main-box-of-chat {
    width: 650px;
  }
  .chat-text-field {
    width: 500px;
  }
  .side-of-chat{
    display: none;
  }
  .chat-header{
    border-top-left-radius: 12px;
  }
  .chat-body{
    border-left: 1px solid #e1e1e1;
    height: 60vh;
  }
  .chat-footer{
    border-left: 1px solid #e1e1e1;
  }
  .search-button{
    display: none;
  }
}

@media only screen and (max-width: 630px) {
    .main-box-of-chat {
        width: 500px;
      }
      .chat-text-field {
        width: 350px;
      }
}

@media only screen and (max-width: 480px) {
    .main-box-of-chat {
        width: 350px;
      }
      .chat-text-field {
        width: 230px;
      }
}

@media only screen and (max-width: 350px) {
  .main-box-of-chat {
      width: 300px;
    }
    .chat-text-field {
      width: 200px;
    }
}