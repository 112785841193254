.aboutus_banner{
    background:url('../../Assets/images/aboutUs-banner.png');
    height: 60vh;
    width: 100%;
    background-position:top center;
    background-repeat: no-repeat;
    background-size: cover;
    display:grid;
    place-items: center;
    position: relative;    
}



@media screen and (max-width:700px) {
    .aboutus_banner{
        height:20vh
    }
}

.aboutus_text{
    margin:5rem 0
}

@media screen  and (max-width:700px) {
        .banner_decorator{
            display: none;
        }
}

.community__highlight{
    background-color: #FBF7ED ;
    margin-top: 5rem;
    padding:4rem 0 
}