.BannerSection {
  background-image: url("src/Assets/images/home/banner-image.jpg");
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}

.BannerSection .list-outer {
  perspective: 1000px;
  perspective-origin: center;
}
.BannerSection .list-outer .list-group {
  transform-style: preserve-3d;

  justify-content: center;
}
.BannerSection .list-outer .list-group div {
  animation: rotate 24s infinite linear;
  transform: rotateX(0deg) translateZ(300px);
}

@keyframes rotate {
  0% {
    transform: rotateX(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes rotate-vertical {
}

@media only screen and (max-width: 420px) {
  .bannerRow {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 675px) {
  .form-control {
    display: block;
    width: 100%;
    padding: 0.7rem 0.75rem;
  }
}

.scrollerBody {
  text-align: center;
}

.scroller {
  height: 4em;
  line-height: 1.2em;
  position: relative;
  overflow: hidden;
  text-align: center;
  font: normal normal medium 96px/140px Recoleta;
  letter-spacing: 0px;
  color: #fbf7ed;
}
.scroller > span {
  position: absolute;
  top: 0;
  /* animation: slide 6s infinite; */
  animation: slide 6s infinite linear;
  font-weight: bold;
}
@keyframes slide {
  0% {
    top: 0;
    opacity: 0.5;
  }
  25% {
    top: -1.2em;
    opacity: 0.5;
  }
  50% {
    top: -2.4em;
    opacity: 1;
  }
  75% {
    top: -3.6em;
    opacity: 0.5;
  }
}

.staticDiv1 {
  width: 105px;
  height: 15px;
  background: #fbf7ed 0% 0% no-repeat padding-box;
  border-radius: 0px 10px 10px 0px;
  opacity: 1;
  margin-bottom: 8%;
}

.staticDiv2 {
  width: 142px;
  height: 15px;
  background: #fbf7ed 0% 0% no-repeat padding-box;
  border-radius: 10px 0px 0px 10px;
  opacity: 1;
}

.blinkingDiv {
  width: 51px;
  height: 12px;
  background: #fbf7ed 0% 0% no-repeat padding-box;
  opacity: 1;
}
.blinkingDiv {
  animation: blinker2 0.6s cubic-bezier(1, 0, 0, 1) infinite alternate;
}
@keyframes blinker2 {
  to {
    opacity: 0;
  }
}
